<template>
  <div class="insert-coach">
    <div class="insert-coach-fields">
      <custom-profile-image-input :disabled="true" v-model="user.image"/>
      <div class="select-coach">
        <custom-validate-input :label="$t('coaches.labels.user')"
                               :styles="{width: '100%'}"
                               :is-empty="true"
                               align="right"
                               v-model="user.name"/>

        <template>
          <div class="suggest-modal-button" @click="suggestUserPromptStatus = true">
            <custom-icon height="22px" width="22px" icon="LIST" color-code="#00bcd4" />
          </div>

          <div class="new-suggest-modal-button" @click="newSuggestUserPromptStatus = true">
            <custom-icon height="22px" width="22px" icon="PLUS" color-code="#28C76F" />
          </div>
        </template>

        <!-- users List prompt -->
        <vs-prompt
          class="very-big-prompt p-0"
          :buttons-hidden="true"
          title=""
          :active.sync="suggestUserPromptStatus"
          @close="suggestUserPromptStatus = true">

          <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
            <vs-row>
              <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                <div @click="handleClick('selectUsers')">
                  <custom-icon icon="CHECK" color="success"/>
                </div>
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                {{ $t('users.select.title')}}
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                <div @click="suggestUserPromptStatus = false">
                  <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <div class="prompt-content py-2 px-3">
            <template>
              <keep-alive>
                <select-users :multi-select="false" @selected="handleSuggestUserSelected($event)"/>
              </keep-alive>
            </template>
          </div>

        </vs-prompt>

        <!-- insert new user prompt -->
        <vs-prompt
          class="prompt p-0"
          :buttons-hidden="true"
          title=""
          :active.sync="newSuggestUserPromptStatus"
          @close="newSuggestUserPromptStatus = true">

          <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
            <vs-row>
              <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                <div @click="handleClick('insertUserBTN')">
                  <custom-icon icon="CHECK" color="success"/>
                </div>
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                {{ $t('users.user.insert.title') }}
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                <div @click="newSuggestUserPromptStatus = false">
                  <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <div class="prompt-content">
            <template>
              <keep-alive>
                <insert-user :in-modal="true" @inserted="handleNewUserInserted($event)"/>
              </keep-alive>
            </template>
          </div>

        </vs-prompt>
      </div>

      <u-radio-input
          id="paymentTypeRadio"
          :label="$t('coaches.labels.paymentType')"
          class="mt-2"
          :options="paymentTypes"
          v-model="user.paymentType"
      />

      <custom-price-input :label="$t('coaches.labels.contractPrice')"
                          :classes="{'w-full': true}"
                          v-model="user.contractPrice"/>

      <custom-price-input :label="$t('coaches.labels.reservePrice')"
                          :classes="{'w-full': true}"
                          v-model="user.reservePrice"/>
    </div>

    <vs-button v-show="false"
               id="insertCoachBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomValidateInput from '../../../../components/customInput/customValidateInput'
  import CustomPriceInput from "../../../../components/customInput/customPriceInput"
  import CustomProfileImageInput from "../../../../components/customInput/customProfileImageInput"
  import CustomIcon from "../../../../components/customIcon/customIcon";
  import SelectUsers from "../../customers/select/selectUsers";
  import InsertUser from "../../customers/insert/insertUser";
  import {checkUserPermissions} from '../../../../assets/js/functions'
  import {insertCoach} from "../../../../http/requests/coaches";
  import URadioInput from "@/components/customInput/URadioInput.vue";

  export default {
    name: 'InsertCoach',
    components: {
      URadioInput,
      InsertUser,
      SelectUsers,
      CustomIcon,
      CustomPriceInput,
      CustomProfileImageInput,
      CustomValidateInput
    },
    props: {
      userId: 0
    },
    data() {
      return {
        suggestUserPromptStatus: false,
        newSuggestUserPromptStatus: false,
        nameRegex: this.$validator('regex.user.name'),
        gender: [
          {
            id: 1,
            label: this.$t('users.genderTypes.man')
          },
          {
            id: 2,
            label: this.$t('users.genderTypes.woman')
          }
        ],
        paymentTypes: [
          {
            label: this.$t('coaches.paymentType.daily'),
            value: 0
          },
          {
            label: this.$t('coaches.paymentType.weekly'),
            value: 1
          },
          {
            label: this.$t('coaches.paymentType.monthly'),
            value: 2
          },
        ],
        user: {
          id: 0,
          name: {
            value: '',
            isValid: true
          },
          image: {
            value: '',
            isValid: true
          },
          contractPrice: {
            value: '',
            isValid: false
          },
          reservePrice: {
            value: '',
            isValid: false
          },
          paymentType: {
            value: 2
          }
        }
      }
    },
    created() {
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },

      handleClick (id) {
        document.getElementById(id).click()
      },

      handleSuggestUserSelected(list) {
        let user = {}
        list.forEach((data) => {
          if (!data.hasOwnProperty('show') || data.show) {
            user = {
              id: data.id,
              name: {
                value: data.name,
                isValid: true
              },
              gender: data.gender === this.$t('genderTypes.man') ? 1 : 2,
              avatar: data.avatar,
            }
          }
        })
        this.user.id = user.id
        this.user.name = user.name
        this.user.image.value = user.avatar
        this.suggestUserPromptStatus = false
      },

      handleNewUserInserted(user) {
        this.user.id = user.id
        this.user.name.value = `${user.name} ${user.family}`
        this.user.image.value = user.avatar || ''
        this.newSuggestUserPromptStatus = false
      },

      sendData() {
        if (this.user.id === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('coaches.notifications.parseError.user'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }
        if (!this.user.contractPrice.isValid) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('coaches.notifications.parseError.contractPrice'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }
        if (!this.user.reservePrice.isValid) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('coaches.notifications.parseError.reservePrice'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        const coach = {
          user_id: this.user.id,
          payment_type: this.user.paymentType.value,
          contract_price: parseInt(this.user.contractPrice.value),
          reserve_price: parseInt(this.user.reservePrice.value),
        }

        insertCoach(coach).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('coaches.notifications.insert.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })

          this.$emit('inserted')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            const error_mapper = {
              'user_id': this.$t('coaches.notifications.parseError.user'),
              'contract_price': this.$t('coaches.notifications.parseError.contractPrice'),
              'reserve_price': this.$t('coaches.notifications.parseError.reservePrice')
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 5000
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('coaches.notifications.insert.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .insert-coach {
    width: 100%;

    .insert-coach-fields {
      .custom-profile-image-input {
        .profile-image-label {
          height: 110px;
          width: 110px;
        }
      }

      .select-coach {
        position: relative;

        .suggest-modal-button, .new-suggest-modal-button {
          position: absolute;
          bottom: 0;
          right: 36px;
          top: 8px;
          width: 25px;
          height: 25px;
          padding: 0;
          border: 1px solid #00bcd4;
          border-radius: .5rem;
          color: #00bcd4;
          background: white;
          cursor: pointer;
        }

        .new-suggest-modal-button {
          right: 6px;
          border: 1px solid #28C76F;
          color: #28C76F;
        }
      }

      .input-field-item {
        position: relative;
        border: 1px solid #cdcdcd;
        border-radius: 0.4rem;
        padding: 5px 10px;
        margin: 15px 0;
        display: flex;
        justify-content: space-around;
        min-height: 35px;
        flex-wrap: wrap;

        &.invalid {
          border-color: #b00000;

          .input-field-label {
            color: #b00000;
          }
        }

        .input-field-label {
          position: absolute;
          font-size: 12px;
          top: -10px;
          left: 8px;
          background-color: #ffffff;
          padding: 0 5px;
        }

        .radio-item {
          display: flex;
          align-items: center;
          width: max-content;
          direction: rtl;

          label {
            direction: ltr;
            margin-left: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &.character-input {
          justify-content: space-between;

          .radio-item {
            justify-content: flex-end;
            width: 33%;
          }
        }
      }

      .custom-input:last-child {
        margin-bottom: 0;
      }

      .input-field-item {
        position: relative;
        border: 1px solid #cdcdcd;
        border-radius: 0.4rem;
        padding: 5px 10px;
        margin: 15px 0;
        display: flex;
        justify-content: space-around;
        min-height: 35px;
        flex-wrap: wrap;

        &.invalid {
          border-color: #b00000;

          .input-field-label {
            color: #b00000;
          }
        }

        .input-field-label {
          position: absolute;
          font-size: 12px;
          top: -10px;
          left: 8px;
          background-color: #ffffff;
          padding: 0 5px;
        }

        .radio-item {
          display: flex;
          align-items: center;
          width: max-content;
          direction: rtl;

          label {
            direction: ltr;
            margin-left: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &.character-input {
          justify-content: space-between;

          .radio-item {
            justify-content: flex-end;
            width: 33%;
          }
        }
      }
    }
  }
</style>
